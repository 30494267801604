import { v4 as uuidv4 } from "uuid";

import { config } from "../config/Constants";

function getHost() {
  var host;
  var userID;
  const userIDLocal = JSON.parse(localStorage.getItem("electera_user_id"));

  if (userIDLocal) {
    userID = userIDLocal;
  } else {
    userID = uuidv4();
  }

  host = config.url.WEBSOCKET_SERVER + "/socket/v1/connect?user-id=" + userID;

  return host;
}

export default getHost;
