import { Box } from "@mui/system";
import { useEffect, useRef, useState } from "react";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import { connect, useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import {
  newSendSeenMessageAction,
  newSendMessageAction,
  newMessageAction,
  newSentMessageListAction,
} from "../../../../store/actions/newMessageAction";

const MapStateToProps = (state) => {
  return { message: state.newMessageReducer, system: state.systemReducer };
};

// Here two things happening:
// 1- When we are in the conversation and see new messages
// 2- When we are in other browser tabs and suddenly come back to the conversation tab
function Received(props) {
  const ref = useRef();
  const dispatch = useDispatch();
  const [sent, setSent] = useState(false);
  const [delayed, setDelayed] = useState(false);
  const isVisible = useOnScreen(ref);

  function sendTextValueHandler(msg) {
    let messageId = uuidv4();
    const inputVal = {
      message: msg,
      amisender: "1",
      senderId: props.system.uuid,
      sendTime: Math.round(new Date().getTime()),
      messageId: messageId,
      seenByPeer: 0,
    };

    dispatch(newSentMessageListAction(messageId));
    dispatch(newMessageAction(inputVal));
    dispatch(newSendMessageAction(inputVal));
  }

  function useOnScreen(ref) {
    const [isIntersecting, setIntersecting] = useState(false);

    useEffect(() => {
      var root = document.getElementById("conv");
      var options_dict = {
        thresholds: [0.0, 0.0, 0.0, 0.0],
      };
      const observer = new IntersectionObserver(
        ([entry]) => setIntersecting(entry.isIntersecting),
        options_dict,
        root
      );
      observer.observe(ref.current);
      return () => {
        observer.disconnect();
      };
    }, [ref]);

    return isIntersecting;
  }

  useEffect(() => {
    function sendSeenMessage() {
      const message = {
        message: props.messageId,
        sendTime: Math.round(new Date().getTime()),
        seenByPeer: 1,
      };

      setTimeout(function () {
        dispatch(newSendSeenMessageAction(message));
      }, 2000);
    }

    // 1- When we are in the conversation and see new messages
    if (!sent && isVisible && delayed && document.hasFocus()) {
      sendSeenMessage();
      if (!sent && delayed) {
        setSent(true);
        setDelayed(false);
      }
    } else if (!delayed) {
      setDelayed(true);
    }
    if (isVisible) {
      if (document.hasFocus()) {
        setSent(true);
        setDelayed(false);
      } else if (!sent) {
        setDelayed(true);
      }
    }

    // 2- When we are in other browser tabs and suddenly come back to the conversation tab
    // User has switched back to the tab
    const onFocus = () => {
      if (delayed && isVisible && !sent) {
        sendSeenMessage();
        if (!sent && delayed) {
          setSent(true);
          setDelayed(false);
        }
      }
    };

    window.addEventListener("focus", onFocus);
    return () => {
      window.removeEventListener("focus", onFocus);
    };
  }, [isVisible, sent, delayed, props.messageId, dispatch]);

  return (
    <>
      <Box ref={ref}>
        {props.messageType === "question" ? (
          <>
            <Box
              sx={{
                backgroundColor: "primary.receivedMessage",
                padding: 2,
                paddingTop: 3,
                paddingBottom: 3,
                borderRadius: 3,
                display: "inline-block",
                marginBottom: 0.8,
              }}>
              <Box sx={{ color: props.textColor }}>{props.children}</Box>
            </Box>

            <Stack direction='row'>
              {props.quickReply ? (
                props.quickReply.map((item, id) => (
                  <Chip
                    key={id}
                    label={item}
                    variant='outlined'
                    sx={{
                      marginRight: 0.4,
                    }}
                    onClick={() =>
                      sendTextValueHandler("@" + props.appName + " " + item)
                    }
                  />
                ))
              ) : (
                <></>
              )}
            </Stack>
          </>
        ) : (
          <Box sx={{ display: "none" }}></Box>
        )}

        {props.messageType === "say" ? (
          <Box
            sx={{
              backgroundColor: "primary.receivedMessage",
              padding: 2,
              paddingTop: 3,
              paddingBottom: 3,
              borderRadius: 3,
              display: "inline-block",
            }}>
            <Box sx={{ color: props.textColor }}>{props.children}</Box>
          </Box>
        ) : (
          <></>
        )}

        {props.messageType === "video" ? (
          <Box
            sx={{
              backgroundColor: "primary.receivedMessage",
              paddingTop: 0,
              paddingLeft: 0,
              paddingRight: 0,
              paddingBottom: 3,
              borderRadius: 3,
              display: "inline-block",
            }}>
            <Box
              sx={{
                webkitBorderRadius: 3,
                mozBorderRadius: 3,
                borderRadius: 3,

                webkitBorderBottomLeftRadius: 0,
                mozBorderBottomLeftRadius: 0,
                borderBottomLeftRadius: 0,

                webkitBorderBottomRightRadius: 0,
                mozBorderBottomRightRadius: 0,
                borderBottomRightRadius: 0,

                overflow: "hidden",
                marginTop: "0px",
              }}>
              <iframe
                src={props.children}
                height='300'
                width='300'
                title='videoMessage'
                frameBorder='0'
                allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen={true}
                webkitallowfullscreen='true'
                mozallowfullscreen='true'></iframe>
            </Box>
            <Box
              sx={{
                color: props.textColor,
                marginTop: "10px",
                marginBottom: "-10px",
                paddingLeft: 2,
                paddingRight: 2,
              }}>
              {props.messageDesc}
            </Box>
          </Box>
        ) : (
          <></>
        )}

        {props.messageType === "paragraph" ? (
          <Box
            sx={{
              backgroundColor: "primary.receivedMessage",
              padding: 2,
              paddingTop: 3,
              paddingBottom: 3,
              borderRadius: 3,
              display: "inline-block",
            }}>
            <Box sx={{ color: props.textColor }}>
              <Box>
                <b>{props.messageTitle}</b>
              </Box>
              <Box>{props.children}</Box>
            </Box>
          </Box>
        ) : (
          <></>
        )}

        {props.messageType === "usermessage" ? (
          <Box
            sx={{
              backgroundColor: "primary.receivedMessage",
              padding: 2,
              paddingTop: 3,
              paddingBottom: 3,
              borderRadius: 3,
              display: "inline-block",
            }}>
            <Box sx={{ color: props.textColor }}>{props.children}</Box>
          </Box>
        ) : (
          <Box sx={{ display: "none" }}></Box>
        )}
      </Box>
    </>
  );
}

export default connect(MapStateToProps)(Received);
