import { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { v4 as uuidv4 } from "uuid";

import { connect, useDispatch } from "react-redux";

import {
  newSendMessageAction,
  newMessageAction,
  newSentMessageListAction,
} from "../../../store/actions/newMessageAction";

import * as snackbarActions from "../../../store/actions/snackbarActions";

const MapStateToProps = (state) => {
  return { message: state.newMessageReducer, system: state.systemReducer };
};

function UserInput(props) {
  const dispatch = useDispatch();
  const [inputText, setInputText] = useState("");

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      sendTextValueHandler();
    }
  };

  function sendTextValueHandler() {
    let messageId = uuidv4();
    if (inputText && props.system.uuid) {
      const inputVal = {
        message: inputText,
        amisender: "1",
        senderId: props.system.uuid,
        sendTime: Math.round(new Date().getTime()),
        messageId: messageId,
        seenByPeer: 0,
      };

      dispatch(newSentMessageListAction(messageId));
      dispatch(newMessageAction(inputVal));
      setInputText("");
      dispatch(newSendMessageAction(inputVal));
    } else {
      dispatch(
        snackbarActions.newSnackbarAction({
          show: true,
          content: "Client: Incomplete data(102)",
          severity: "error",
          autoHide: true,
        })
      );
    }
  }

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 0,
        right: 0,
        left: 0,
        padding: "0 !important",
        width: "100%",
        borderTop: "1px solid #c9c7c7",
        backgroundColor: "#f6f3eb",
      }}>
      <Box sx={{ maxWidth: "100%", padding: "5px !important" }}>
        <Stack spacing={1} direction='row'>
          <TextField
            fullWidth
            value={inputText}
            onKeyDown={handleKeyDown}
            onChange={(e) => setInputText(e.target.value)}
            sx={{
              "& fieldset": { border: "none" },
            }}
          />
          <Button
            variant='contained'
            onClick={() => sendTextValueHandler()}
            disabled={inputText ? false : true}>
            Send
          </Button>
        </Stack>
      </Box>
    </Box>
  );
}

export default connect(MapStateToProps)(UserInput);
