import * as React from "react";
import { connect } from "react-redux";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";

const MapStateToProps = (state) => {
  return {
    wsReducer: state.websocketReducer,
  };
};

function CustomAppBar(props) {
  const { t } = useTranslation();
  return (
    <>
      <AppBar>
        <Toolbar>
          <Typography variant='h6' component='div'>
            <Stack direction='row'>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                <img
                  src={process.env.PUBLIC_URL + "/icons/sheldon.svg"}
                  alt='sheldon logo'
                  width='25px'
                  height='25px'
                />
              </Box>
              <Box>&nbsp;</Box>
              <Box>{t("APPBAR APP NAME")}</Box>
            </Stack>
          </Typography>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </>
  );
}

export default connect(MapStateToProps)(CustomAppBar);
