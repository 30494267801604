export const authTokenAction = (value) => ({
  type: "SYSTEM_AUTH_TOKEN",
  payload: value,
});

export const sessionRoomAction = (value) => ({
  type: "SYSTEM_SESSION_ROOM",
  payload: value,
});

export const sessionUserAction = (value) => ({
  type: "SYSTEM_SESSION_USER",
  payload: value,
});

export const userUUID = (value) => ({
  type: "SYSTEM_USER_UUID",
  payload: value,
});

export const setThemeColorAction = (value) => ({
  type: "SYSTEM_THEME_COLOR",
  payload: value,
});

export const setThemeModeAction = (value) => ({
  type: "SYSTEM_THEME_MODE",
  payload: value,
});

export const setThemeDirAction = (value) => ({
  type: "SYSTEM_THEME_DIR",
  payload: value,
});
