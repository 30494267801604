import axios from "axios";

import { config } from "../../config/Constants";

export const newMessageAction = (value) => ({
  type: "MESSAGE_NEW_LOCAL",
  payload: value,
});

export const newReceivedMessageListAction = (value) => ({
  type: "MESSAGE_NEW_RECEIVED",
  payload: value,
});

export const newSendMessageAction = (value) => ({
  type: "MESSAGE_NEW_SEND_TO_SOCKET",
  payload: value,
});

export const newSentMessageListAction = (value) => ({
  type: "MESSAGE_NEW_SENT_LIST",
  payload: value,
});

export const loadMoreLoadingAction = (value) => ({
  type: "MESSAGE_LOAD_MORE_LOADING_STATUS",
  payload: value,
});

export const newSendSeenMessageAction = (value) => ({
  type: "MESSAGE_SEEN_SEND",
  payload: value,
});

export const newReceivedSeenMessageAction = (value) => ({
  type: "MESSAGE_SEEN_RECEIVED",
  payload: value,
});

export const newRoomAction = (value) => ({
  type: "ROOM_NEW_ID",
  payload: value,
});

export const loadMoreAction = (value) => ({
  type: "MESSAGE_LOAD_MORE",
  payload: value,
});

export const newMessageArrived = (value) => ({
  type: "MESSAGE_NEW2",
  payload: value,
});

export const fetchNewMessages = (value) => {
  return (dispatch) => {
    return axios
      .get(config.url.MESSAGING_SERVER + "/auth/v1/test")
      .then((response) => {
        dispatch(newMessageArrived(response.data.message));
      })
      .catch((error) => {
        throw error;
      });
  };
};
