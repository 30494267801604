const newMessageReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case "MESSAGE_NEW_LOCAL":
      var i;
      for (i = 0; i < state.messages.length; i++) {
        //if message already exist, don't add it again
        if (state.messages[i].messageId === payload.messageId) {
          return { ...state };
        }
      }

      //too keep messages list always sorted
      let ix = 0;
      while (ix < state.messages.length) {
        if (
          Math.max(payload.sendTime, state.messages[ix].sendTime) ===
          payload.sendTime
        ) {
          ix++;
        } else {
          break;
        }
      }

      // we cone messages because of react/redux immutability issue
      const clonedMessages = [...state.messages];
      clonedMessages.splice(ix, 0, payload);
      return Object.assign({}, state, {
        messages: clonedMessages,
      });

    case "MESSAGE_NEW_SENT_LIST":
      if (state.sent_messages_list.indexOf(payload) === -1) {
        return Object.assign({}, state, {
          sent_messages_list: state.sent_messages_list.concat(payload),
        });
      } else {
        return { ...state };
      }

    case "MESSAGE_NEW_RECEIVED":
      if (state.received_messages_list.indexOf(payload) === -1) {
        return Object.assign({}, state, {
          received_messages_list: state.received_messages_list.concat(payload),
        });
      } else {
        return { ...state };
      }

    case "MESSAGE_SEEN_RECEIVED":
      return { ...state, seen: payload };

    case "ROOM_NEW_ID":
      return { ...state, id: payload };

    case "MESSAGE_LOAD_MORE_LOADING_STATUS":
      return {
        ...state,
        load_more_loading_status: payload.load_more_loading_status,
      };

    default:
      return state;
  }
};

export default newMessageReducer;
