import * as React from "react";
import Box from "@mui/material/Box";
import { connect } from "react-redux";

import UserInput from "../UserInput/UserInput";
import Conversation from "../Conversation/Conversation";
import CustomAppBar from "../../UI/CustomAppBar/CustomAppBar";
import SnackBarMessage from "../../UI/Snackbar";

const MapStateToProps = (state) => {
  return {
    system: state.systemReducer,
  };
};

function Chat(props) {
  return (
    <React.Fragment>
      <Box dir={props.system.themeDir}>
        <SnackBarMessage />
        <CustomAppBar />
        <Conversation />
        <UserInput />
      </Box>
    </React.Fragment>
  );
}

export default connect(MapStateToProps)(Chat);
