import * as React from "react";
import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import { loadProgressBar } from "axios-progress-bar";

import Chat from "../Chat/Chat";
import { wsConnect } from "../../store/actions/websocketAction";
import getHost from "../../internal/websocketConnect";
import setTheme from "../../styles/theme/mainTheme";
import {
  setThemeModeAction,
  setThemeDirAction,
  setThemeColorAction,
} from "../../store/actions/systemAction";
import {
  getThemeMode,
  getThemeColor,
  getThemeDir,
} from "../../internal/saveTheme";

const MapStateToProps = (state) => {
  return { system: state.systemReducer };
};

function App(props) {
  const dispatch = useDispatch();
  loadProgressBar({ showSpinner: false });

  useEffect(() => {
    let host = getHost();
    dispatch(wsConnect(host));
    if (getThemeColor()) {
      dispatch(setThemeColorAction(getThemeColor().themeColor));
    }

    if (getThemeDir()) {
      dispatch(setThemeDirAction(getThemeDir().themeDir));
    }

    if (getThemeMode()) {
      dispatch(setThemeModeAction(getThemeMode().themeMode));
    }
  }, [dispatch]);

  //we use this for dynamically change the color wth changing state
  const dynamicTheme = React.useMemo(
    () =>
      createTheme(
        setTheme(
          props.system.themeColor,
          props.system.themeMode,
          props.system.themeDir
        )
      ),
    [props.system.themeColor, props.system.themeMode, props.system.themeDir]
  );

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <>
            <ThemeProvider theme={dynamicTheme}>
              <CssBaseline />
              <Chat />
            </ThemeProvider>
          </>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default connect(MapStateToProps)(App);
