import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import translationFA from "./locales/fa/translation.json";
import translationEN from "./locales/en/translation.json";

// the translations
const resources = {
  fa: {
    translation: translationFA,
  },
  en: {
    translation: translationEN,
  },
};

const DetectorOptions = {
  // order and from where user language should be detected
  order: [
    "querystring",
    "cookie",
    "localStorage",
    "navigator",
    "htmlTag",
    "path",
    "subdomain",
  ],

  // keys or params to lookup language from
  lookupQuerystring: "lng",
  lookupCookie: "i18next",
  lookupLocalStorage: "i18nextLng",
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,

  // cache user language on
  caches: ["localStorage", "cookie"],
  excludeCacheFor: ["cimode"], // languages to not persist (cookie, localStorage)

  // optional expire and domain for set cookie
  cookieMinutes: 10,
  cookieDomain: "myDomain",

  // optional htmlTag with lang attribute, the default is:
  htmlTag: document.documentElement,

  // only detect languages that are in the whitelist
  checkWhitelist: true,
  // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
  cookieOptions: { path: "/" },
};

i18n
  // .use(reactI18nextModule) // passes i18n down to react-i18next
  .use(initReactI18next)
  .use(detector)
  .init({
    resources,
    // lng: "fa", //remove it if you want to use /?lng=fa
    fallbackLng: "en",
    keySeparator: false,
    detection: DetectorOptions, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

//setting default language
if (
  localStorage.getItem("i18nextLng") === "en-US" ||
  !localStorage.getItem("i18nextLng")
) {
  i18n.changeLanguage("en");
}

export default i18n;
