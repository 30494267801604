import { useRef, useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { connect, useDispatch } from "react-redux";
import Sent from "../Messages/Sent";
import Received from "../Messages/Received";
import {
  loadMoreAction,
  loadMoreLoadingAction,
} from "../../../store/actions/newMessageAction";

const MapStateToProps = (state) => {
  return {
    message: state.newMessageReducer,
    errors: state.errorReducer,
    socket: state.websocketReducer,
  };
};

function Conversation(props) {
  const dispatch = useDispatch();
  const messagesEndRef = useRef(null);
  const messagesStartRef = useRef(null);
  const [scrollToBottomState, setScrollToBottomState] = useState(true);
  const [firstLoad, setFirstLoad] = useState(true);
  const [showLoadMoreLabel, setShowLoadMoreLabel] = useState("none");

  useEffect(() => {
    const checkScrollStatus = () => {
      const bottom =
        Math.ceil(window.innerHeight + window.scrollY) >=
        document.documentElement.scrollHeight;

      //if we return scroll to bottom, it means we want to enable automatic scroll
      //when new messages arrives.
      if (bottom) {
        setScrollToBottomState(true);
      }

      //if scroll is at the top, then load more
      if (window.pageYOffset === 0) {
        var sendTime = "9999999999";
        if (
          props.message &&
          props.message.messages &&
          props.message.messages[0] &&
          props.message.messages[0].sendTime
        ) {
          sendTime = String(props.message.messages[0].sendTime);
          setScrollToBottomState(false);
        }
        dispatch(
          loadMoreAction({
            lastMessageTime: sendTime,
            lastMessageId: "",
            loadCount: 10,
          })
        );

        dispatch(
          loadMoreLoadingAction({
            load_more_loading_status: true,
          })
        );

        setShowLoadMoreLabel("flex");
      }
    };
    if (!document.hidden) {
      //if false, then automatic scroll to bottom will be disabled
      //for example if we click on load more at the top of chat screen,
      //then automatic scroll to bottom will be disabled
      if (scrollToBottomState) {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
      }
    }

    //check if socket is active, then load first batch of old messages
    //from database at startup
    if (firstLoad && props.socket.connecting === false) {
      var sendTime = "9999999999999";
      if (
        props.message &&
        props.message.messages &&
        props.message.messages[0] &&
        props.message.messages[0].sendTime
      ) {
        sendTime = String(props.message.messages[0].sendTime);
        setScrollToBottomState(false);
      }
      dispatch(
        loadMoreAction({
          lastMessageTime: sendTime,
          lastMessageId: "",
          loadCount: 10,
        })
      );
      setShowLoadMoreLabel("flex");
      setFirstLoad(false);
    }

    //handling if scroll position is at the bottom or not
    window.addEventListener("scroll", checkScrollStatus, {
      passive: true,
    });

    return () => {
      window.removeEventListener("scroll", checkScrollStatus);
    };
    //
  }, [
    props.message,
    scrollToBottomState,
    dispatch,
    firstLoad,
    props.socket.connected,
    props.socket.connecting,
  ]);

  function loadMore() {
    var sendTime = "9999999999999";
    if (
      props.message &&
      props.message.messages &&
      props.message.messages[0] &&
      props.message.messages[0].sendTime
    ) {
      sendTime = String(props.message.messages[0].sendTime);
      setScrollToBottomState(false);
    }
    dispatch(
      loadMoreAction({
        lastMessageTime: sendTime,
        lastMessageId: "",
        loadCount: 10,
      })
    );
  }

  return (
    <Container
      id='conv'
      sx={{
        backgroundColor: "background.default",
        overflow: "auto",
        paddingTop: "15px",
        marginBottom: "70px",
      }}>
      <Box
        display={showLoadMoreLabel}
        sx={{
          width: "100%",
          justifyContent: "center",
        }}
        onClick={() => loadMore()}>
        {props.message.load_more_loading_status === true
          ? "Loading More Messages"
          : ""}
      </Box>
      <Box sx={{ height: 0 }} ref={messagesStartRef} />
      <Stack spacing={1}>
        {props.message.messages.map((msg, i) =>
          props.message.sent_messages_list.includes(msg.messageId) ? (
            msg.amisender === "1" ? (
              <Stack direction='row' key={i}>
                <Box
                  sx={{
                    minWidth: "30%",
                    display: "flex",
                    justifyContent: "flex-start",
                  }}></Box>
                <Box
                  sx={{
                    minWidth: "70%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}>
                  <Sent
                    key={i}
                    color={"#000000"}
                    textColor={"#ffffff"}
                    messageId={msg.messageId}>
                    {msg.message}
                  </Sent>
                </Box>
              </Stack>
            ) : (
              <Box key={i} sx={{ display: "none" }}></Box>
            )
          ) : (
            <Stack direction='row' key={i}>
              <Box
                sx={{
                  minWidth: "70%",
                  display: "flex",
                  justifyContent: "flex-start",
                }}>
                <Received
                  key={i}
                  color={"#ededed"}
                  textColor={"#000000"}
                  quickReply={msg.quickReply}
                  messageType={msg.messageType}
                  appName={msg.appName}
                  messageId={msg.messageId}
                  messageTitle={msg.title}
                  messageDesc={msg.messageDesc}>
                  {msg.message}
                </Received>
              </Box>

              <Box
                sx={{
                  minWidth: "30%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}></Box>
            </Stack>
          )
        )}
      </Stack>
      <br />
      <Box sx={{ height: 0 }} ref={messagesEndRef} />
    </Container>
  );
}

export default connect(MapStateToProps)(Conversation);
