const websocketInitialState = {};

const websocketReducer = (state = { ...websocketInitialState }, action) => {
  switch (action.type) {
    case "WS_CONNECT":
      return { ...state, host: action.host };

    case "WS_CONNECTING":
      return { ...state, connecting: action.connecting };

    // case "WS_CONNECTED":
    //   return { ...state, connected: action.connected };

    default:
      return state;
  }
};

export default websocketReducer;
