import { Box } from "@mui/system";
import { useState, useEffect } from "react";
import { connect } from "react-redux";

const MapStateToProps = (state) => {
  return {
    message: state.newMessageReducer,
  };
};

function Sent(props) {
  const [checkSent, setCheckSent] = useState(false);
  const [receivedReceipt, setReceivedReceipt] = useState(false);
  const [messageId, setMessageId] = useState();
  const [seen, setSeen] = useState(false);

  useEffect(() => {
    if (props.message.seen !== undefined) {
      if (props.message.seen.message === messageId) {
        setSeen(true);
      }
    }

    if (
      props.message.received_messages_list.find(
        (o) => o.messageId === messageId
      ) !== undefined
    ) {
      setReceivedReceipt(true);
    }
    setMessageId(props.messageId);
    setTimeout(function () {
      setCheckSent(true);
    }, 5000);
  }, [
    props.messageId,
    props.message.seen,
    messageId,
    props.message.received_messages_list,
  ]);

  return (
    <>
      <Box
        sx={{
          backgroundColor:
            checkSent === true
              ? receivedReceipt
                ? seen
                  ? "#000000"
                  : "#2b2b2b"
                : "#ff3838"
              : seen
              ? "#000000"
              : "#2b2b2b",

          padding: 3,
          paddingTop: 3,
          paddingBottom: 3,
          borderRadius: 3,
          display: "inline-block",
        }}>
        <Box
          sx={{
            color: receivedReceipt ? (seen ? "#ffffff" : "#b3b3b3") : "#ffffff",
          }}>
          {props.children}
        </Box>
      </Box>
    </>
  );
}

export default connect(MapStateToProps)(Sent);
