export function saveThemeMode(theme) {
  if (theme) {
    localStorage.setItem("electera_theme_mode", JSON.stringify(theme));
  }
}

export function getThemeMode() {
  return JSON.parse(localStorage.getItem("electera_theme_mode"));
}

export function saveThemeColor(theme) {
  if (theme) {
    localStorage.setItem("electera_theme_color", JSON.stringify(theme));
  }
}

export function getThemeColor() {
  return JSON.parse(localStorage.getItem("electera_theme_color"));
}

export function saveThemeDir(theme) {
  if (theme) {
    localStorage.setItem("electera_theme_dir", JSON.stringify(theme));
  }
}

export function getThemeDir() {
  return JSON.parse(localStorage.getItem("electera_theme_dir"));
}
