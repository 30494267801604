let specificStatusCodeMappings = {
  1000: "WebSocket Normal Closure Error(106)",
  1001: "WebSocket Going Away Error(107)",
  1002: "WebSocket Protocol Error(108)",
  1003: "WebSocket Unsupported Data Error(109)",
  1004: "WebSocket Error(110)",
  1005: "WebSocket No Status Received Error(111)",
  1006: "WebSocket Abnormal Closure Error(112)",
  1007: "WebSocket Invalid Frame Payload Data Error(113)",
  1008: "WebSocket Policy Violation Error(114)",
  1009: "WebSocket Message Too Big Error(115)",
  1010: "WebSocket Missing Extension Error(116)",
  1011: "WebSocket Internal Error(117)",
  1012: "WebSocket Service Restart Error(118)",
  1013: "WebSocket Try Again Later Error(119)",
  1014: "WebSocket Bad Gateway Error(120)",
  1015: "WebSocket TLS Handshake Error(121)",
};

export function getCloseReason(code) {
  if (code >= 0 && code <= 999) {
    return "WebSocket Unused Error(122)";
  } else if (code >= 1016) {
    if (code <= 1999) {
      return "WebSocket Standard Error(123)";
    } else if (code <= 2999) {
      return "WebSocket Extension Error(124)";
    } else if (code <= 3999) {
      return "WebSocket Libraries and Frameworks Error(125)";
    } else if (code <= 4999) {
      return "WebSocket Applications Error(126)";
    }
  }
  if (typeof specificStatusCodeMappings[code] !== "undefined") {
    return specificStatusCodeMappings[code];
  }
  return "Websocket Connection Error(127)";
}
