import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import newMessageReducer from "./reducers/newMessageReducer";
import websocketReducer from "./reducers/websocketReducer";
import websocketMiddleware from "../middlewares/websocketMiddleware";
import systemReducer from "./reducers/systemReducer";
import snackbarReducer from "./reducers/snackbarReducer";

const middleware = [thunk, websocketMiddleware];

const allReducers = combineReducers({
  newMessageReducer: newMessageReducer,
  websocketReducer: websocketReducer,
  systemReducer: systemReducer,
  snackbarReducer: snackbarReducer,
});

const initialState = {
  newMessageReducer: {
    messages: [],
    received_messages_list: [],
    sent_messages_list: [],
    load_more_loading_status: false,
  },
  systemReducer: {
    activeRoom: "",
    userName: "",
    authToken: "",
    themeColor: "#000000",
    themeMode: "light",
    themeDir: "ltr",
  },
  snackbarReducer: { message: { content: "", type: 0, show: false } },
};

// Creation of the main store
const store = createStore(
  allReducers,
  initialState,
  compose(applyMiddleware(...middleware))
);
export default store;
