const snackbarReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case "SNACKBAR_NEW":
      return { ...state, message: payload };

    case "SNACKBAR_RESET":
      return {
        ...state,
        message: { content: "", severity: "info", show: false },
      };

    default:
      return state;
  }
};

export default snackbarReducer;
