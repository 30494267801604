const prod = {
  url: {
    REPLY_SERVER: "https://reply.electera.net",
    ACCOUNTS_SERVER: "https://accounts.electera.net",
    MESSAGING_SERVER: "https://messaging.electera.net",
    WEBSOCKET_SERVER: "wss://messaging.electera.net",
  },
};
const dev = {
  url: {
    REPLY_SERVER: "http://localhost:4023",
    ACCOUNTS_SERVER: "http://localhost:4022",
    MESSAGING_SERVER: "http://localhost:4021",
    WEBSOCKET_SERVER: "ws://localhost:4021",
  },
};
export const config = process.env.NODE_ENV === "development" ? dev : prod;
