import * as actions from "../store/actions/websocketAction";
import * as snackbarActions from "../store/actions/snackbarActions";
import * as ReconnectingWebSocket from "../internal/websocketReconnect";

import {
  newMessageAction,
  newReceivedMessageListAction,
  newReceivedSeenMessageAction,
  newSentMessageListAction,
  loadMoreLoadingAction,
} from "../store/actions/newMessageAction";
import { userUUID } from "../store/actions/systemAction";
import { getAuthToken, setAuthToken } from "../internal/authToken";
import { getCloseReason } from "../internal/websocketClose";
import { setUserId, getUserId } from "../internal/userInfo";

function saveMessage(payload, store, message, messageType) {
  if (messageType === "loadmorechunkmessage") {
    payload = message;
  }

  var newMessage = payload.message;

  if (getUserId() === payload.sender.id && payload.appName === undefined) {
    //if this message sender is me, store it on message lists(if not exist)
    payload.amisender = "1";
    store.dispatch(newSentMessageListAction(payload.messageId));
  } else {
    payload.amisender = "0";
    if (newMessage.substring(0, 1) === "@") {
      // if message sender is not me, remove @ sign from the begining of the message
      newMessage = newMessage.substring(newMessage.indexOf(" ") + 1);
    }
  }

  if (payload.messageId) {
    store.dispatch(
      newReceivedMessageListAction({ messageId: payload.messageId })
    );
  }

  store.dispatch(
    newMessageAction({
      message: newMessage,
      amisender: payload.amisender,
      sendTime: payload.sendTime,
      messageId: payload.messageId,
      senderId: payload.sender.id,
      seenByPeer: 0,
      messageType: payload.messageType,
      quickReply: payload.quickReply,
      title: payload.title,
      messageDesc: payload.messageDesc,
      appName: payload.appName,
    })
  );
}
const socketMiddleware = () => {
  let socket = null;
  let roomId = null;
  let wsURL = null;

  const onOpen = (store) => (event) => {
    store.dispatch(actions.wsConnected(event.target.url));
  };

  const onClose = (store) => (event) => {
    var reason = getCloseReason(event.code);

    store.dispatch(
      snackbarActions.newSnackbarAction({
        show: true,
        content: "Client: " + reason,
        severity: "error",
        autoHide: true,
      })
    );
  };

  const onError = (store) => (event) => {
    store.dispatch(
      snackbarActions.newSnackbarAction({
        show: true,
        content: "Client: Websocket Unknown Internal Error(106)",
        severity: "error",
        autoHide: true,
      })
    );
  };

  const onConnecting = (store) => (event) => {
    store.dispatch(actions.wsConnecting(true));
  };

  const onMessage = (store) => (event) => {
    var payload;
    payload = JSON.parse(event.data);
    // console.log(payload);
    if (payload && !payload.errors) {
      switch (payload.action) {
        case "room-joined":
          roomId = payload.target.roomId;
          break;

        case "user-join":
          if (payload.sender.id) {
            store.dispatch(userUUID(payload.sender.id));
          }
          break;

        case "seen-by-peer":
          store.dispatch(newReceivedSeenMessageAction(payload));
          break;

        //just for receiving messages from server
        case "send-message":
          if (payload.messageId) {
            store.dispatch(
              newReceivedMessageListAction({ messageId: payload.messageId })
            );
          }

          //registering user
          if (payload.isSystem === 1) {
            switch (payload.system.action) {
              case "user-registered":
                //change user data after login
                setAuthToken(payload.system.token);
                setUserId(payload.system.userId);
                store.dispatch(userUUID(payload.system.userId));
                break;

              default:
                break;
            }
          } else {
            if (!payload.messageType) {
              payload.messageType = "usermessage";
            }

            if (payload.messageType === "loadmorechunkmessage") {
              store.dispatch(
                loadMoreLoadingAction({
                  load_more_loading_status: false,
                })
              );
              JSON.parse(payload.message)?.forEach(function (item) {
                saveMessage(payload, store, item, "loadmorechunkmessage");
              });
            } else {
              saveMessage(payload, store, payload, "usermessage");
            }
          }

          break;

        default:
          break;
      }
    } else {
      if (payload.errors && payload.errors.status === 1) {
        if (payload.errors.errorType === "snack") {
          store.dispatch(
            snackbarActions.newSnackbarAction({
              show: payload.errors.errorShow,
              content: payload.errors.errorContent,
              severity: payload.errors.errorSeverity,
              autoHide: payload.errors.errorAutoHide,
            })
          );
        }
      }
    }
  };

  return (store) => (next) => (action) => {
    if (store && action) {
      switch (action.type) {
        case "WS_CONNECT":
          store.dispatch(actions.wsConnecting(true));
          wsURL = action.host;

          if (socket !== null) {
            socket.close();
          }

          if (wsURL) {
            // connect to the remote host
            socket = new ReconnectingWebSocket(wsURL, null, {
              debug: false,
              reconnectInterval: 3000,
            });

            if (!socket) {
              store.dispatch(
                snackbarActions.newSnackbarAction({
                  show: true,
                  content:
                    "Client: Can't establish a socket connection to the server(104)",
                  severity: "error",
                  autoHide: true,
                })
              );

              socket.onError = onError(store);
            } else {
              socket.onopen = onOpen(store);
              socket.onclose = onClose(store);
            }
          } else {
            store.dispatch(
              snackbarActions.newSnackbarAction({
                show: true,
                content: "Client: Websocket connection URL is not correct(105)",
                severity: "error",
                autoHide: true,
              })
            );
          }

          break;

        case "WS_CONNECTED":
          store.dispatch(actions.wsConnecting(false));

          // websocket handlers
          socket.onmessage = onMessage(store);
          socket.onconnecting = onConnecting(store);

          break;

        case "WS_DISCONNECT":
          if (socket) {
            socket.close();
          }
          socket = null;

          store.dispatch(
            snackbarActions.newSnackbarAction({
              show: true,
              content: "Client: Websocket disconnected(128)",
              severity: "warning",
              autoHide: true,
            })
          );
          break;

        case "MESSAGE_NEW_SEND_TO_SOCKET":
          let token = getAuthToken();
          if (action.payload) {
            socket.send(
              JSON.stringify({
                action: "send-message",
                message: action.payload.message,
                sendTime: action.payload.sendTime,
                messageId: action.payload.messageId,
                authToken: token,
                client: "webchat",
                clienVersion: "1.0",
                target: {
                  roomId: roomId,
                },
              })
            );
          } else {
            store.dispatch(
              snackbarActions.newSnackbarAction({
                show: true,
                content: "Client: Socket payload is incomplete(103)",
                severity: "error",
                autoHide: true,
              })
            );
          }
          break;

        case "MESSAGE_SEEN_SEND":
          if (action.payload) {
            socket.send(
              JSON.stringify({
                action: "seen-by-peer",
                message: action.payload.message,
                sendTime: action.payload.sendTime,
                messageId: action.payload.messageId,
                authToken: getAuthToken(),
                seenByPeer: 1,
                target: {
                  roomId: roomId,
                },
              })
            );
          }
          break;

        case "MESSAGE_LOAD_MORE":
          if (action.payload && socket) {
            socket.send(
              JSON.stringify({
                action: "load-more",
                message: "",
                authToken: getAuthToken(),
                lastMessageId: action.payload.lastMessageId,
                lastMessageTime: action.payload.lastMessageTime,
                loadCount: action.payload.loadCount,
              })
            );
          }
          break;

        default:
          return next(action);
      }
    } else {
      store.dispatch(
        snackbarActions.newSnackbarAction({
          show: true,
          content: "Client: Internal Error(101)",
          severity: "error",
          autoHide: true,
        })
      );
    }
  };
};

export default socketMiddleware();
